<template>
  <div class="px-0 lg:px-20">
    <!--Search Bar -->
    <div
      class="
        p-4
        my-5
        flex flex-row
        items-center
        justify-center
        lg:justify-between
        rounded-lg
        shadow
        bg-white
      "
    >
      <h1
        class="
          hidden
          lg:inline
          text-2xl
          font-semibold
          text-gray-700
          font-EffraR
          flex-1
        "
      >
        <i class="fas fa-flask"></i> Médicaments de:
        {{ patient.gender === "MALE" ? "Mr" : "Mme" }}.{{
          patient.lastName + " " + patient.firstName
        }}
      </h1>
      <div class="flex flex-row items-center gap-3 md:gap-3 mr-5">
        <button
          @click="openPicker"
          class="
            bg-transparent
            border-2 border-solid border-dokBlue-ultra
            py-3
            px-5
            rounded-cu
            cursor-pointer
            text-dokBlue-ultra
            hover:bg-dokBlue-ultra
            hover:text-white
            hover:border-white
          "
        >
          <i class="fa fa-calendar"></i>
        </button>
        <datepicker
          ref="programaticOpen"
          input-class="hidden"
          @selected="dateOfLastVisit"
          format="yyyy-MM-dd"
          :language="dpLang"
        ></datepicker>
        <input
          v-model="filterName"
          class="
            w-full
            xl:w-300
            bg-white
            border border-solid border-gray-300
            rounded-cu
            px-6
            py-3
          "
          placeholder="Rechercher par Nom du médicament"
        />
        <button
          @click="loadMedicaments()"
          class="
            border-2 border-solid border-nblue
            text-nblue
            py-3
            px-5
            bg-white
            rounded-cu
            font-bold
            text-lg
            cursor-pointer
            transition
            duration-200
            ease-in-out
            hover:bg-nblue
            hover:text-white
            font-EffraR
          "
        >
          Filtrer
        </button>
      </div>
    </div>

    <!--Loader -->
    <div class="h-full w-full" v-if="loader">
      <div
        class="
          bg-white bg-opacity-60
          px-8
          py-2
          h-screen
          w-full
          flex
          justify-center
          items-center
        "
      >
        <loader></loader>
      </div>
    </div>

    <!--Data -->
    <div
      v-if="!loader"
      class="
        bg-white
        border-0 border-b border-solid border-gray-300
        py-3
        px-3
        grid grid-cols-6
        gap-1
        shadow
      "
    >
      <span
        class="
          hidden
          lg:block
          font-bold
          text-base
          mb-2
          xl:mb-0
          font-EffraR
          col-span-2
        "
        >Nom Médicament</span
      >
      <span
        class="
          lg:hidden
          font-bold
          text-base
          mb-2
          xl:mb-0
          font-EffraR
          col-span-2
          text-center
        "
        >Médicament</span
      >
      <span class="font-bold text-base mb-2 xl:mb-0 font-EffraR">Quantité</span>
      <span class="hidden lg:block font-bold text-base mb-2 xl:mb-0 font-EffraR"
        >Vendu le</span
      >
      <span
        class="
          lg:hidden
          font-bold
          text-base
          mb-2
          xl:mb-0
          font-EffraR
          text-center
        "
        >Le</span
      >
      <span class="hidden lg:block font-bold text-base mb-2 xl:mb-0 font-EffraR"
        >Vendu par</span
      >
      <span
        class="
          lg:hidden
          font-bold
          text-base
          mb-2
          xl:mb-0
          font-EffraR
          text-center
        "
        >Par</span
      >
      <span class="font-bold text-base mb-2 xl:mb-0 font-EffraR"></span>
    </div>
    <!--Data patients -->
    <div
      class="
        bg-white
        border-0 border-b border-solid border-gray-300
        py-3
        px-3
        grid grid-cols-6
        gap-1
        shadow
      "
      v-for="(med, i) in servedDrugs"
      :key="i"
      :class="i === 0 ? 'rounded-t-lg' : ''"
    >
      <span class="font-EffraR text-base col-span-2">{{ med.name }}</span>
      <span class="font-EffraL text-base text-center lg:text-left">{{
        med.quantity
      }}</span>
      <div>
        <span class="font-EffraL text-base hidden md:inline">{{
          moment()(med.created_at).format("DD MMM YYYY")
        }}</span>
        <span class="font-EffraL text-base md:hidden">{{
          moment()(med.created_at).format("DD/MM")
        }}</span>
      </div>
      <span class="font-EffraL text-base">{{
        med.servedBy.lastName + " " + med.servedBy.firstName
      }}</span>
      <div class="flex justify-end">
        <button
          @click="
            $router.push({
              name: 'pharmacyPrescriptionByID',
              params: { id: med.prescription }
            })
          "
          class="
            hidden
            md:inline
            py-2
            px-5
            w-auto
            font-EffraR
            bg-transparent
            hover:bg-blackdok
            text-blackdok
            hover:text-white
            border-2 border-solid border-blackdok
            rounded-cu
            cursor-pointer
          "
        >
          Ordonnance
        </button>
        <button
          @click="
            $router.push({
              name: 'pharmacyPrescriptionByID',
              params: { id: med.prescription }
            })
          "
          class="
            md:hidden
            py-2
            px-3
            w-auto
            font-EffraR
            bg-transparent
            hover:bg-blackdok
            text-blackdok
            hover:text-white
            border-2 border-solid border-blackdok
            rounded-cu
            cursor-pointer
          "
        >
          <i class="fas fa-file-prescription"></i>
        </button>
      </div>
    </div>
    <!-- Null Data patients -->
    <div
      v-if="!servedDrugs || (servedDrugs.length == 0 && !loader)"
      class="bg-white 0 px-8 py- w-full flex justify-center items-center"
    >
      <img alt="vide" src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
    </div>
    <!-- pagination -->
    <div class="mt-4 py-5 flex justify-center items-center" v-if="pages > 1">
      <div class="flex flex-row">
        <div>
          <a
            href="javascript:void(0)"
            @click="
              page--;
              loadMedicaments();
            "
            :class="page == 1 ? 'disable ' : ''"
            class="
              px-4
              py-4
              mx-5
              border-0
              shadow-sm
              text-nblue
              rounded-full
              cursor-pointer
              hover:shadow-lg
              hover:text-gray-100
              hover:bg-nblue
            "
            ><i class="fa fa-angle-left" aria-hidden="true"></i
          ></a>
        </div>
        <div v-for="item in pages" :key="item">
          <a
            href="javascript:void(0)"
            @click="
              page = item;
              loadMedicaments();
            "
            :class="
              page == item
                ? 'shadow-lg text-gray-100 bg-nblue disable'
                : 'text-nblue'
            "
            class="
              px-4
              py-4
              mx-1
              border-0
              shadow-sm
              rounded-full
              cursor-pointer
              hover:shadow-lg
              hover:text-gray-100
              hover:bg-nblue
            "
            >{{ item }}</a
          >
        </div>
        <div>
          <a
            href="javascript:void(0)"
            @click="
              page++;
              loadMedicaments();
            "
            :class="page == pages || pages == 0 ? 'disable ' : ''"
            class="
              px-4
              py-4
              mx-5
              border-0
              shadow-sm
              text-nblue
              rounded-full
              cursor-pointer
              hover:shadow-lg
              hover:text-gray-100
              hover:bg-nblue
            "
            ><i class="fa fa-angle-right" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
const loader = () => import("@/views/global-components/loader");
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
export default {
  data() {
    return {
      page: 1,
      pages: 1,
      servedDrugs: null,
      patient: {},
      loader: true,
      filterName: null,
      dpLang: fr
    };
  },
  async mounted() {
    this.loadMedicaments();
  },
  components: { loader, Datepicker },
  methods: {
    moment() {
      return moment;
    },
    async loadMedicaments() {
      try {
        this.loader = true;
        const { ok, data, patient, pages } = await this.$store.dispatch(
          "pharmacy/GET_PATIENT_DRUGS",
          {
            id: this.$route.params.id,
            filterName: this.filterName,
            page: this.page
          }
        );
        if (ok) {
          this.servedDrugs = data;
          this.patient = patient;
          this.pages = pages;
        }
      } finally {
        this.loader = false;
      }
    },
    openPicker() {
      this.$refs.programaticOpen.showCalendar();
    },
    async dateOfLastVisit(newVal) {
      try {
        this.loader = true;
        const { ok, data, patient, pages } = await this.$store.dispatch(
          "pharmacy/GET_PATIENT_DRUGS",
          {
            id: this.$route.params.id,
            filterName: this.filterName,
            filterDate: moment(newVal).format("YYYY-MM-DD"),
            page: this.page
          }
        );
        if (ok) {
          this.servedDrugs = data;
          this.patient = patient;
          this.pages = pages;
        }
      } finally {
        this.loader = false;
      }
    }
  }
};
</script>
<style scoped>
.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
